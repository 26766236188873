import { Component, ViewChild, OnInit, inject, effect } from '@angular/core';
import { DashboardChartsService } from 'src/app/api/services/reports/dashboard/dashboard-charts.service';
import { DashboardStore } from 'src/app/api/services/reports/dashboard/dashboard.store';
import 
  { ApexAxisChartSeries, ChartComponent, ApexNonAxisChartSeries, ApexResponsive, ApexChart, ApexDataLabels, ApexPlotOptions, ApexXAxis, ApexYAxis, ApexStroke, ApexLegend, ApexFill, ApexTooltip, NgApexchartsModule } from "ng-apexcharts";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { NgIf, NgStyle, DecimalPipe } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';

export type PieChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  stroke: ApexStroke;
  title: any;
  colors: any;
  legend: ApexLegend;
  tooltip: ApexTooltip;
};

export type BarChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  responsive: ApexResponsive[];
  colors: any;
};

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    standalone: true,
    imports: [MatTabsModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, NgIf, NgStyle, NgApexchartsModule, RouterLink, DecimalPipe]
})
export class DashboardComponent implements OnInit {
  currentTabIndex = 0;
  readonly dashboardStore = inject(DashboardStore);
  @ViewChild("chart") chart!: ChartComponent;
  public paidCertsOptions!: Partial<PieChartOptions>;
  public orgSubscriptionsRevenue!: Partial<BarChartOptions>;
  public courseRegCertOptions!: Partial<BarChartOptions>;
  public courseFreeCertOptions!: Partial<BarChartOptions>;
  public courseAllCertOptions!: Partial<BarChartOptions>;
  public OrganizationRevenueMonthly!: Partial<BarChartOptions>;
  public TotalRevenueMonthly!: Partial<BarChartOptions>;
  
  filtersForm!: FormGroup;
  displayCharts1 = false;
  displayCharts2 = false;

  dailyTotalPurchases: any;
  dailyTotalCertificates: any;
  dailyTotalPayAsYouGo: any;
  dailyTotalSubscriptions: any;
  dailyTotalScorm: any;
  dailyTotalRenewal: any;

  trendTotalPurchases: any;
  trendTotalCertificates: any;
  trendTotalPayAsYouGo: any;
  trendTotalSubscriptions: any;
  trendTotalScorm: any;
  trendTotalRenewal: any;

  totalCertificates: any;
  totalSubsciptionsByPlan: number = 0;

  totalPaidRegistration: any;
  totalPaidInprogress: any;
  totalPaidCertificates: any;
  totalPaidPurchased: any;

  totalFreeRegistration: any;
  totalFreeInprogress: any;
  totalFreeCertificates: any;

  totalAllRegistration: any;
  totalAllInprogress: any;
  totalAllCertificates: any;



  paidCertsTotal: any;
  orgSubscriptionsTotal: any;

  chartWidth = 1200;

  // for Daily Report cert Bar Charts
  certChartsStrokeWidth = 2;
  certBarCatagoryHeightDecimal = 0.9;
  certBarHeight = 5;

  constructor(
    private fb: FormBuilder,
    private dashboardService: DashboardChartsService,
  ) { 
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()-1);
    const startDateTrend = new Date(2023, 8, 1);

    this.filtersForm = this.fb.group({
      fromDateDaily: this.fb.control(startDate || null),
      toDateDaily: this.fb.control(startDate || null),
      fromDateTrend: this.fb.control(startDateTrend || null),
      toDateTrend: this.fb.control(startDate || null),
    });
    this.getDailyReport();
    this.getTrendReport();

    effect(() => {

      const dailyData = this.dashboardStore.dailyDataSignal();
      if (!dailyData) {
        return;
      }

      var colors = ["#000033", "#29B494", "#C1C1C1", "#FF0F0F", "#595959", "#FDC602", "#7BAAF7", "#D10CE8", "#F07B72", "#FCD04F"];

      this.dailyTotalPurchases = dailyData.totalPurchases;
      this.dailyTotalCertificates = dailyData.totalCertificates;
      this.dailyTotalPayAsYouGo = dailyData.totalPayAsYouGo;
      this.dailyTotalSubscriptions = dailyData.totalSubscriptions;
      this.dailyTotalScorm = dailyData.totalScorm;
      this.dailyTotalRenewal = dailyData.totalRenewal;

      // Chart 1 Data Paid Certificates by Course
      const firstChart = dailyData.adminDashboardCharts![0];
      var chartData1: any[] = [];
      var chartLabels1: any[] = [];
      this.totalCertificates = firstChart?.totalCount1Tally;
      firstChart?.chart?.forEach((chart: any) => {
        chartData1.push(chart.count1.total);
        chartLabels1.push(chart.description1.description);
      });
      if (chartLabels1.length % (colors.length + 1) === 0) {
        colors.pop();
      }
      this.paidCertsOptions = {
        series: chartData1,
        chart: {
          width: this.chartWidth,
          type: "pie"
        },
        labels: chartLabels1,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 450
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ],
        stroke: {
          show: true,
          width: 0
        },
        colors: colors,
        title: {
          text: "Paid Certificates by Course"
        },
        legend: {
          offsetY: -20,
          formatter: function (val: any, opts: any) {
            const label = opts.w.globals.labels[opts.seriesIndex];
            const value = opts.w.globals.series[opts.seriesIndex].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return `<div style="display: flex; justify-content: space-between;">
                      <div style="text-align: left;">${label}</div>
                      <div style="text-align: right; padding-left:40px;">${value}</div>
                    </div>`;
          }
        },
        tooltip: {
          y: {
            formatter: function (val: any) {
              return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
          }
        }
      };

      // Chart 2 Subscriptions Revenue
      const secondChart = dailyData.adminDashboardCharts![1];
      var chartMoneyData2: any[] = [];
      var chartCountData2: any[] = [];
      var chartLabels2: any[] = [];
      var chartTick2: any = 0;

      secondChart?.chart?.forEach((chart: any) => {
        this.totalSubsciptionsByPlan = this.totalSubsciptionsByPlan + chart.count1.total;

        // Chart 2 Subscriptions Revenue Data
        chartMoneyData2.push(chart.money1.moneyTotal);
        chartCountData2.push(chart.count1.total);

        chartTick2 = chart.count1.total > chartTick2 ? chart.count1.total : chartTick2;
        chartLabels2.push(`${this.getFirstWord(chart.description1.description)} ${chart.description2.description}`);
      });
      chartTick2 = chartTick2 < 10 ? chartTick2 : undefined;

      // Chart 2 Subscriptions Revenue
      this.orgSubscriptionsRevenue = {
        series: [{
          name: "Subscription Revenue",
          data: chartMoneyData2
        
        }],
        dataLabels: {
          formatter: (val) => {
            return this.formatToDollarAmount(+val);
          }
        },
        chart: {
          type: "bar",
          height: 750,
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        xaxis: {
          categories: chartLabels2,
          labels: {
            formatter: (val) => {
              return this.formatToDollarAmount(+val);
            }
          }
        },
        yaxis: {
          floating: true,
          labels: {
            minWidth: 450,
            maxWidth: 900,
            style: {
              colors: "#000033"
            }
          } 
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return this.formatToDollarAmount(+val);
            }
          }
        },
        colors: ["#000033"],
      };


      // Chart 3 Learner PAID Courses by registration
      const thirdChart = dailyData.adminDashboardCharts![2];
      var chartColumn1Data3: any[] = [];
      var chartColumn1Labels3: any[] = [];
      var chartColumn2Data3: any[] = [];
      var chartColumn3Data3: any[] = [];
      var chartColumn4Data3: any[] = [];
      this.totalPaidRegistration = thirdChart?.totalCount1Tally
      this.totalPaidInprogress = thirdChart?.totalCount2Tally
      this.totalPaidCertificates = thirdChart?.totalCount3Tally
      this.totalPaidPurchased = thirdChart?.totalCount4Tally
      thirdChart?.chart?.forEach((chart: any) => {
        chartColumn1Data3.push(chart.count1.total);
        chartColumn1Labels3.push(chart.description1.description);
        chartColumn2Data3.push(chart.count2.total);
        chartColumn3Data3.push(chart.count3.total);
        chartColumn4Data3.push(chart.count4.total);
      });
      this.courseRegCertOptions = {
        series: [
          {
            name: "Registered",
            data: chartColumn1Data3
          },
          {
            name: "In Progress",
            data: chartColumn2Data3
          },
          {
            name: "Completed",
            data: chartColumn3Data3
          },
          {
            name: "Paid",
            data: chartColumn4Data3
          }
        ],
        chart: {
          type: "bar",
          height: this.adjustChartHeight(chartColumn1Labels3.length, 4),
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: this.decimalToPercentage(this.certBarCatagoryHeightDecimal),
          }
        },
        dataLabels: {
          enabled: false,
          formatter: (val) => {
            return this.formatNumber(+val);
          }
        },
        stroke: {
          show: true,
          width: this.certChartsStrokeWidth,
          colors: ["transparent"]
        },
        xaxis: {
          categories: chartColumn1Labels3,
          min: undefined,
          max: undefined,
          labels: {
            maxHeight: 120,
            formatter: (val) => {
              return this.formatNumber(+val);
            }
          } 
        },
        yaxis: {
          floating: true,
          labels: {
            minWidth: 450,
            maxWidth: 900,
            style: {
              colors: "#000033"
            }
          } 
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return this.formatNumber(+val);
            }
          }
        },
        colors: ["#000033", "#29B494", "#C1C1C1", "#FF0F0F", "#595959", "#FDC602", "#7BAAF7", "#D10CE8", "#F07B72", "#FCD04F"],
      };


      // Chart 4 Learner FREE Courses by registration
      const fourthChart = dailyData.adminDashboardCharts![3];
      var chartColumn1Data4: any[] = [];
      var chartColumn1Labels4: any[] = [];
      var chartColumn2Data4: any[] = [];
      var chartColumn3Data4: any[] = [];
      this.totalFreeRegistration = fourthChart?.totalCount1Tally
      this.totalFreeInprogress = fourthChart?.totalCount2Tally
      this.totalFreeCertificates = fourthChart?.totalCount3Tally
      fourthChart?.chart?.forEach((chart: any) => {
        chartColumn1Data4.push(chart.count1.total);
        chartColumn1Labels4.push(chart.description1.description);
        chartColumn2Data4.push(chart.count2.total);
        chartColumn3Data4.push(chart.count3.total);
      });

      this.courseFreeCertOptions = {
        series: [
          {
            name: "Registered",
            data: chartColumn1Data4
          },
          {
            name: "In Progress",
            data: chartColumn2Data4
          },
          {
            name: "Completed",
            data: chartColumn3Data4
          },
        ],
        chart: {
          type: "bar",
          height: this.adjustChartHeight(chartColumn1Labels4.length, 3),
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: this.decimalToPercentage(this.certBarCatagoryHeightDecimal),
          }
        },
        dataLabels: {
          enabled: false,
          formatter: (val) => {
            return this.formatNumber(+val);
          }
        },
        stroke: {
          show: true,
          width: this.certChartsStrokeWidth,
          colors: ["transparent"]
        },
        xaxis: {
          categories: chartColumn1Labels4,
          min: undefined,
          max: undefined,
          labels: {
            maxHeight: 120,
            formatter: (val) => {
              return this.formatNumber(+val);
            }
          } 
        },
        yaxis: {
          floating: true,
          labels: {
            minWidth: 450,
            maxWidth: 900,
            style: {
              colors: "#000033"
            }
          } 
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: (val) => {
              return this.formatNumber(+val);
            }
          }
        },
        colors: ["#000033", "#29B494", "#C1C1C1", "#FF0F0F", "#595959", "#FDC602", "#7BAAF7", "#D10CE8", "#F07B72", "#FCD04F"],
      };

            // Chart 5 ALL Courses by registration
            const fifthChart = dailyData.adminDashboardCharts![4];
            var chartColumn1Data5: any[] = [];
            var chartColumn1Labels5: any[] = [];
            var chartColumn2Data5: any[] = [];
            var chartColumn3Data5: any[] = [];
            this.totalAllRegistration = fifthChart?.totalCount1Tally
            this.totalAllInprogress = fifthChart?.totalCount2Tally
            this.totalAllCertificates = fifthChart?.totalCount3Tally
            fifthChart?.chart?.forEach((chart: any) => {
              chartColumn1Data5.push(chart.count1.total);
              chartColumn1Labels5.push(chart.description1.description);
              chartColumn2Data5.push(chart.count2.total);
              chartColumn3Data5.push(chart.count3.total);
            });
      
            this.courseAllCertOptions = {
              series: [
                {
                  name: "Registered",
                  data: chartColumn1Data5
                },
                {
                  name: "In Progress",
                  data: chartColumn2Data5
                },
                {
                  name: "Completed",
                  data: chartColumn3Data5
                },
              ],
              chart: {
                type: "bar",
                height: this.adjustChartHeight(chartColumn1Labels5.length, 3),
                animations: {
                  enabled: true,
                  easing: 'easeinout',
                  speed: 800,
                  animateGradually: {
                      enabled: true,
                      delay: 150
                  },
                  dynamicAnimation: {
                      enabled: true,
                      speed: 350
                  }
                }
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  barHeight: this.decimalToPercentage(this.certBarCatagoryHeightDecimal),
                }
              },
              dataLabels: {
                enabled: false,
                formatter: (val) => {
                  return this.formatNumber(+val);
                }
              },
              stroke: {
                show: true,
                width: this.certChartsStrokeWidth,
                colors: ["transparent"]
              },
              xaxis: {
                categories: chartColumn1Labels5,
                min: undefined,
                max: undefined,
                labels: {
                  maxHeight: 120,
                  formatter: (val) => {
                    return this.formatNumber(+val);
                  }
                } 
              },
              yaxis: {
                floating: true,
                labels: {
                  minWidth: 450,
                  maxWidth: 900,
                  style: {
                    colors: "#000033"
                  }
                } 
              },
              fill: {
                opacity: 1
              },
              tooltip: {
                y: {
                  formatter: (val) => {
                    return this.formatNumber(+val);
                  }
                }
              },
              colors: ["#000033", "#29B494", "#C1C1C1", "#FF0F0F", "#595959", "#FDC602", "#7BAAF7", "#D10CE8", "#F07B72", "#FCD04F"],
            };
      this.displayCharts1 = true;
    });




  effect(() => {
    const trendData = this.dashboardStore.trendDataSignal();
    if (!trendData) {
      return;
    }
    this.trendTotalPurchases = trendData.totalPurchases;
    this.trendTotalCertificates = trendData.totalCertificates;
    this.trendTotalPayAsYouGo = trendData.totalPayAsYouGo;
    this.trendTotalSubscriptions = trendData.totalSubscriptions;
    this.trendTotalScorm = trendData.totalScorm;
    this.trendTotalRenewal = trendData.totalRenewal;

    // Chart 5 Organization Revenue
    const fifthChart = trendData.adminDashboardCharts![0];
    var displayMonth5: any[] = [];
    var monthlySubscriptions: any[] = [];
    var monthlyRenewals: any[] = [];
    var monthlyPAYG: any[] = [];
    var monthlyEnterprise: any[] = [];
    var monthlyScorm: any[] = [];

    fifthChart?.chart?.forEach((chart: any) => {
      displayMonth5.push(chart.description2.name);
      monthlySubscriptions.push(chart.money1.moneyTotal);
      monthlyRenewals.push(chart.money2.moneyTotal);
      monthlyPAYG.push(chart.money3.moneyTotal);
      monthlyEnterprise.push(chart.money4.moneyTotal);
      monthlyScorm.push(chart.money5.moneyTotal);
    });
    
    this.OrganizationRevenueMonthly = {
      series: [
        {
          name: "Subscriptions",
          data: monthlySubscriptions
        },
        {
          name: "Renewals",
          data: monthlyRenewals
        },
        {
          name: "Pay-As-You-Go",
          data: monthlyPAYG
        },
        {
          name: "Enterprise",
          data: monthlyEnterprise
        },
        {
          name: "SCORM",
          data: monthlyScorm
        }
      ],
      chart: {
        type: "bar",
        height: 700,
        stacked: true,
        toolbar: {
          show: true
        },
      },
      dataLabels: {
        formatter: (val) => {
          return this.formatToDollarAmount(+val);
        }
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      yaxis: {
        labels: {
          formatter: (val) => {
            return this.formatToDollarAmount(+val);
          }
        }
      },
      xaxis: {
        type: "category",
        categories: displayMonth5
      },
      tooltip: {
        y: {
          formatter: (val) => {
            return this.formatToDollarAmount(+val);
          }
        }
      },
      colors: ["#000033", "#29B494", "#FF0F0F", "#595959", "#FDC602", "#7BAAF7", "#D10CE8", "#F07B72", "#FCD04F"],
    };

    // Chart 6 Total Revenue
    const sixthChart = trendData.adminDashboardCharts![1];
    var displayMonth6: any[] = [];
    var monthlyOrgRevenue: any[] = [];
    var monthlyIndCertRevenue: any[] = [];

    sixthChart?.chart?.forEach((chart: any) => {
      displayMonth6.push(chart.description2.name);
      monthlyIndCertRevenue.push(chart.money1.moneyTotal);
      monthlyOrgRevenue.push(chart.money2.moneyTotal);
    });

    this.TotalRevenueMonthly = {
      series: [
        {
          name: "Ind Certs",
          data: monthlyIndCertRevenue
        },
        {
          name: "Org Rev",
          data: monthlyOrgRevenue
        },
      ],
      chart: {
        type: "bar",
        height: 700,
        stacked: true,
        toolbar: {
          show: true
        },
      },
      dataLabels: {
        formatter: (val) => {
          return this.formatToDollarAmount(+val);
        }
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      yaxis: {
        labels: {
          formatter: (val) => {
            return this.formatToDollarAmount(+val);
          }
        }
      },
      xaxis: {
        type: "category",
        categories: displayMonth6
      },
      tooltip: {
        y: {
          formatter: (val) => {
            return this.formatToDollarAmount(+val);
          }
        }
      },
      colors: ["#000033", "#29B494", "#FF0F0F", "#595959", "#FDC602", "#7BAAF7", "#D10CE8", "#F07B72", "#FCD04F"],
    };
    this.displayCharts2 = true;
    }); 
  }

  ngOnInit(): void {
    let previousValue = this.filtersForm.value;

    this.filtersForm.valueChanges.subscribe((newValue: any) => {
      if (previousValue.fromDateDaily != newValue.fromDateDaily || previousValue.toDateDaily != newValue.toDateDaily) {
        this.getDailyReport();
      }
      if (previousValue.fromDateTrend != newValue.fromDateTrend || previousValue.toDateTrend != newValue.toDateTrend) {
        this.getTrendReport();
      }
      
    previousValue = newValue;
    });

  }

  getDailyReport() {
    const { fromDateDaily, toDateDaily } = this.filtersForm.value;
    const filter = {
      fromDate: this.getDateString(fromDateDaily),
      toDate: this.getDateString(toDateDaily),
    };
    this.dashboardService.dailyReport(filter).subscribe();
  }

  getTrendReport() {
    const { fromDateTrend, toDateTrend } = this.filtersForm.value;
    const filter = {
      fromDate: this.getDateString(fromDateTrend),
      toDate: this.getDateString(toDateTrend),
    };
    this.dashboardService.trendReport(filter).subscribe();
  }

  getDateString(date: any) {
    if (date) {
      const newDate = new Date(date);
      return newDate.toUTCString();
    }
    return '';
  }

  formatNumber(value: number): string {
    if (value == null) {
      return '';
    }
    const roundedValue = Math.round(value);
    return `${roundedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  }

  formatToDollarAmount(value: number): string {
    return `$${this.formatNumber(value)}`;
  }

  getFirstWord(input: string): string {
    if (!input || input.trim().length === 0) {
      return '';
    }
    const words = input.trim().split(' ');
    
    if (words[1] === 'Business') {
      return words[0] || '';
    }
    return input;

  }

  onTabChange(event: any) {
    this.currentTabIndex = event.index;
  }

  decimalToPercentage(value: number): string {
    return `${(value * 100).toFixed(2)}%`;
  }

  adjustChartHeight(numberOfItems: number, numberOfBars: number): number {
    const barHeight = this.certBarHeight;
    const barGap = this.certChartsStrokeWidth;
    const categoryFillRatio = this.certBarCatagoryHeightDecimal;
    const miscConstantHeaderFooter = 117;

    // Compute the height occupied by bars in a single category
    const categoryContentHeight = numberOfBars * (barHeight + barGap) - barGap;
  
    // Adjust to respect the 90% rule (so that categories do not take full height)
    const adjustedCategoryHeight = categoryContentHeight + categoryContentHeight * (1 - categoryFillRatio);
  
    return (numberOfItems * adjustedCategoryHeight) + miscConstantHeaderFooter;
  }
}
